<template>
  <h2>Регулярные выражения</h2>
  <p>Для ознакомления с темой рекомендую посмотреть первые 9 видео из <a class="link" href="https://www.youtube.com/playlist?list=PLFkWVcok-WQwdv6pHEtRKUQPXxhHgELA6" target="_blank">этого плейлиста на YouTube</a></p>
  <p>Также рекомендую ознакомиться:</p>
  <p><a class="link" href="https://regexone.com/" target="_blank">Интерактивный тренажёр по регулярным выражениям</a></p>
  <p><a class="link" href="https://medium.com/nuances-of-programming/%D1%88%D0%BF%D0%B0%D1%80%D0%B3%D0%B0%D0%BB%D0%BA%D0%B0-%D0%BF%D0%BE-%D1%80%D0%B5%D0%B3%D1%83%D0%BB%D1%8F%D1%80%D0%BD%D1%8B%D0%BC-%D0%B2%D1%8B%D1%80%D0%B0%D0%B6%D0%B5%D0%BD%D0%B8%D1%8F%D0%BC-%D0%B2-%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D0%B0%D1%85-53820a5f3435" target="_blank">Шпаргалка по регулярным выражениям</a></p>
  <p>Если хочется почитать: <a class="link" href="https://learn.javascript.ru/regular-expressions" target="_blank">подробно про регулярные выражения на learn.javascript.ru</a>. Если не хочется, то ознакомьтесь хотя бы с темами 1, 11, 12 и 17</p>
</template>

<script>
export default {
  props: ['task']
}
</script>
