/*
Регулярные выражения:
https://medium.com/nuances-of-programming/%D1%88%D0%BF%D0%B0%D1%80%D0%B3%D0%B0%D0%BB%D0%BA%D0%B0-%D0%BF%D0%BE-%D1%80%D0%B5%D0%B3%D1%83%D0%BB%D1%8F%D1%80%D0%BD%D1%8B%D0%BC-%D0%B2%D1%8B%D1%80%D0%B0%D0%B6%D0%B5%D0%BD%D0%B8%D1%8F%D0%BC-%D0%B2-%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D0%B0%D1%85-53820a5f3435
• простые регулярки: /a/, /dog/
• якоря: ^ $
• квантификаторы: * + ? и {} (жадные и нежадные)
• Оператор ИЛИ — | или []
• Символьные классы — \d \w \s и их отрицания \D, \W и \S, точка
• Флаги g, m, i
• Скобочные группы ― ()
• Скобочные выражения ― []
• Обратные ссылки — \1
• Примеры валидации
• Примеры поиска с заменой
*/

import RegexIntro from './RegexIntro'
import CodeTest from '@/components/task-templates/CodeTest'

export default [
  {
    component: RegexIntro,
    label: '!'
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Валидация телефона',
    text: 'Напишите функцию \\(validatePhone\\), которая принимает строку \\(s\\) и возвращает булево значение - является ли эта строка корректным номером телефона. Телефон должен начинаться с 7 или +7, после которых должны идти 10 цифр',
    note: null,
    examples: [
      {
        input: `
          console.log(
              [
                  '+79998887777',
                  '79998887777',
                  '+79876543210',
                  '70000000000'
              ].map(validatePhone)
          )
          console.log(
              [
                  '798765432100',
                  '7987654321',
                  '+89876543210',
                  '89876543210'
              ].map(validatePhone)
          )
        `,
        output: `
          [true, true, true, true]
          [false, false, false, false]
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Валидация имени',
    text: 'Напишите функцию \\(validateName\\), которая принимает строку \\(s\\) и возвращает булево значение - является ли эта строка корректным именем. Имя должно начинаться с заглавной русской буквы, все остальные буквы русские строчные, не допускается других символов',
    note: null,
    examples: [
      {
        input: `
          console.log(
              [
                  'Иван',
                  'Фёдор',
                  'Ёлка'
              ].map(validateName)
          )
          console.log(
              [
                'иван',
                ' Фёдор',
                'Ёлка ',
                'Ив@н',
                'Иван777',
              ].map(validateName)
          )
        `,
        output: `
          [true, true, true]
          [false, false, false, false, false]
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Валидация фамилии',
    text: 'Напишите функцию \\(validateSurname\\), которая принимает строку \\(s\\) и возвращает булево значение - является ли эта строка корректной фамилией. Фамилия может быть обычной или двойной. Обычная валидируется как имя, в случае двойной фамилии обе части валидируются как имя и пишутся через дефис',
    note: null,
    examples: [
      {
        input: `
          console.log(
              [
                  'Иванова',
                  'Ерёмин',
                  'Плешаков-Качалин'
              ].map(validateSurname)
          )
          console.log(
              [
                'Из-Трёх-Частей',
                'артёмов',
                'Плешаков-качалин',
                'Римский Корсаков',
                'Ф@милия',
                'Римский-Кор$аков'
              ].map(validateSurname)
          )
        `,
        output: `
          [true, true, true]
          [false, false, false, false, false, false]
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Валидация отчества',
    text: 'Напишите функцию \\(validatePatronymic\\), которая принимает строку \\(s\\) и возвращает булево значение - является ли эта строка корректным отчеством. Отчество может отсутствовать, либо валидируется как имя',
    note: null,
    examples: [
      {
        input: `
          console.log(
              [
                  'Иванович',
                  'Петровна',
                  ''
              ].map(validatePatronymic)
          )
          console.log(
              [
                'Иван0вич',
                'ИванOвич',
                'петровна'
              ].map(validatePatronymic)
          )
        `,
        output: `
          [true, true, true]
          [false, false, false]
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Валидация логина',
    text: "Напишите функцию \\(validateLogin\\), которая принимает строку \\(s\\) и возвращает булево значение - является ли эта строка корректным логином. Логин может содержать только латинские буквы, цифры и символы . (точка) и - (дефис). Начинается только с буквы, заканчивается не на '.' и не на '-', а также не может содержать два спецсимвола (точку или дефис) подряд",
    note: null,
    examples: [
      {
        input: `
          console.log(
              [
                'a',
                'A',
                'A-z',
                'A.z',
                'IvAn777',
                'Abra-cadabra',
                'Abra-cada-bra',
                'Abra-cada.bra',
                'Ab12-22.33'
              ].map(validateLogin)
          )
          console.log(
              [
                'Ab12--22.33',
                'Ab12-.22.33',
                'Ab12..22.33',
                'Ab12.22.33.',
                '1van',
                ''
              ].map(validateLogin)
          )
        `,
        output: `
          [true, true, true, true, true, true, true, true, true]
          [false, false, false, false, false, false]
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Валидация домена',
    text: 'Напишите функцию \\(validateDomain\\), которая принимает строку \\(s\\) и возвращает булево значение - является ли эта строка корректным доменом. Домен состоит из названия и зоны. Название может состоять из одной или нескольких частей, разделённых точками. Каждая часть может содержать латинские буквы, цифры и дефис. Дефис не может стоять в начале, в конце части или рядом с другим дефисом. Зона отделяется точкой и состоит только из латинских букв (минимум двух)',
    note: null,
    examples: [
      {
        input: `
          console.log(
              [
                'google.com',
                'goo.gle.com',
                'goo-gle.com',
                'goo-gle.com.ua',
                'SomeSite.Ru',
                '123456.ru'
              ].map(validateDomain)
          )
          console.log(
              [
                'google.c0m',
                'google-com',
                'google-.com',
                'goo--gle.com',
                'y@ndex.ru',
                'yandex.u'
              ].map(validateDomain)
          )
        `,
        output: `
          [true, true, true, true, true, true]
          [false, false, false, false, false, false]
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Валидация e-mail',
    text: 'Напишите функцию \\(validateEmail\\), которая принимает строку \\(s\\) и возвращает булево значение - является ли эта строка корректным e-mail. E-mail по сути состоит из логина и домена, разделённых @',
    note: null,
    examples: [
      {
        input: `
          console.log(
              [
                'post@gmail.com',
                't-e-s-t@yandex.ru',
                'S.T.A.L.K.E.R@yandex.ru',
                'my.ac-count@y-a.123.Ru',
                'a@b.ru'
              ].map(validateEmail)
          )
          console.log(
              [
                'support@domain.inf0',
                'supp-.port@domain.info',
                'sup@port@domain.info',
                'support@domain.i',
                'support#domain.info',
              ].map(validateEmail)
          )
        `,
        output: `
          [true, true, true, true, true]
          [false, false, false, false, false]
        `
      }
    ]
  }
]
